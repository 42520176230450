/* components/footer/Footer.css */
.footer {
    background-color: #343a40;
    color: white;
  }
  
  .footer h5 {
    margin-bottom: 15px;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  