.sobre-nosotros {
    background-color: #f9f9f9;
    padding: 60px 0;
    min-height: 100vh; /* Añadir esta línea para asegurar que ocupa toda la altura del viewport */
}

.sobre-nosotros h2 {
    color: #333;
    margin-bottom: 20px;
}

.sobre-nosotros p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

.sobre-nosotros .img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}
