.contact-section {
    padding: 80px 0;
    background-color: #f9f9f9;
    min-height: 100vh;
}

.contact-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    justify-content: center;
}

.card-body {
    padding: 30px;
}

.card-footer {
    background-color: #f9f9f9;
    text-align: center;
    border-top: 1px solid #ddd;
    padding: 15px;
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center; /* Centrar el título */
}

.contact-info {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px; /* Añadir un margen inferior */
}

.contact-info li {
    margin-bottom: 10px;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Añadir un margen inferior */
}

.social-icon {
    font-size: 24px;
    color: #555;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #000;
}

.btn-whatsapp {
    background-color: #25D366 !important; /* Añadir !important para asegurar que el color sea aplicado */
    color: #ffffff !important; /* Añadir !important para asegurar que el color sea aplicado */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.btn-whatsapp:hover {
    background-color: #128C7E !important; /* Añadir !important para asegurar que el color sea aplicado */
}

.map-container {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
