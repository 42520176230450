.NavbarP{
  background-color: #FAF756;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  width: 100%;
}

.NavbarP {
  font-size: 20px;
  text-align: center;
  justify-self: center;
}

.NavbarP .sub-nav {
  justify-content: center;
  width: 100%;
  font-size: '50px';
}