/*
.productos {
    background-image: url("../images/Fondo-Productos.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    position: relative;
}

.productos::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    width: 100%;
    height: 100%;
    z-index: 1;
}

.productos .container {
    position: relative;
    z-index: 2;
    color: white;
    padding-top: 20px;
}

.card {
    background-color: white;
}

.card-title, .card-text {
    color: black;
}


*/

/* productos.css */
.productos {
    position: relative;
    background-image: url("../images/Fondo-Productos.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
}

.productos::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Opacidad del fondo */
    z-index: 1;
}

.productos .container {
    position: relative;
    z-index: 2;
    color: white;
}

.product-card {
    width: 100%;
    max-width: 300px;
    background-color: white !important; /* Asegura que las cards sean blancas */
    text-align: center;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 3; /* Asegura que las cards estén por encima del fondo opaco */
}

.product-card .card-title, .product-card .card-text {
    color: black !important; /* Asegura que el texto dentro de las cards sea negro */
}

.product-card .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

h2 {
    z-index: 2;
    position: relative;
}
