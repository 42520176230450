/*
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
  }
  
  body {
    margin: 0;
  }
  
  .home {
    background-image: url("./images/palta.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  .home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
 
  .content {
    position: relative;
    z-index: 2;
    color: white;
    padding: 20px;
    max-width: 100%;
    text-align: left;
  }
  
  .home h1 {
    color: white;
    font-size: 55px;
    margin: 20px 0 10px 0;
    padding: 0;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 900;
    position: relative;
    z-index: 2; 
  }
  
  .home p {
    color: white;
    font-size: 25px;
    margin-top: 20px;
    padding: 0 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    text-align: left;
    position: relative;
    z-index: 2;
  }
  
  .contact-link {
    text-decoration: none;
  }
  
  .contact-button {
    background-color: limegreen;
    color: white;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    margin: 20px auto 0 auto;
  }
  

  .carousel-container {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1000px; 
    margin: 20px auto;
    padding: 0; 
  }
  

  @media (min-width: 768px) {
    .home {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  
    .content {
      text-align: left;
    }
  
    .carousel-container {
      margin: 0;
    }
  }
  
  @media (max-width: 767px) {
    .content,
    .carousel-container {
      width: 100%;
      padding: 0;
    }
  }

*/
  

/*   ESTILO DE CAROUSEL 2 


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; 
}

body {
  margin: 0;
}

.home {
  background-image: url("./images/palta.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0;
  box-sizing: border-box;
}


.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  width: 100%;
  height: 100%;
  z-index: 1;
}


.content {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  max-width: 100%;
  text-align: left;
}

.home h1 {
  color: white;
  font-size: 55px;
  margin: 20px 0 10px 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 900;
  position: relative;
  z-index: 2; 
}

.description {
  color: white;
  font-size: 25px;
  margin-top: 20px;
  padding: 0 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-align: center; 
  position: relative;
  z-index: 2;
}

.contact-link {
  text-decoration: none;
}

.contact-button {
  background-color: limegreen;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 20px auto 0 auto;
}


.carousel-container {
  position: relative;
  z-index: 2;
  width: 80%; 
  max-width: 600px; 
  margin: 20px auto;
  padding: 0; 
}

@media (min-width: 768px) {
  .home {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .content {
    text-align: left;
  }

  .carousel-container {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .content,
  .carousel-container {
    width: 100%;
    padding: 0;
  }
}


*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

body {
  margin: 0;
}

.home {
  background-image: url("./images/palta.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0;
  box-sizing: border-box;
}

/* Overlay */
.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Opacidad en el fondo */
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Content styling */
.content {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  max-width: 100%;
  text-align: left;
}

.home h1 {
  color: white;
  font-size: 55px;
  margin: 20px 0 10px 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 900;
  position: relative;
  z-index: 2; /* Ensure the title is above the overlay */
}

.description {
  color: white;
  font-size: 25px;
  padding: 0 20px 10px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 20px; /* Remove margin */
}

.contact-link {
  text-decoration: none;
}

.contact-button {
  background-color: limegreen;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 20px auto 0 auto;
}

/* Carousel container styling */
.carousel-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center align */
  padding: 0; /* Ensure no padding is added */
}

/* Responsive styles */
@media (min-width: 768px) {
  .home {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .content {
    text-align: left;
  }

  .carousel-container {
    margin: 0;
  }
}

/* Ajustes para pantallas de tamaño normal */
.home h1 {
  position: relative;
  font-size: 56px; /* Ajusta según sea necesario */
}

/* Añade un pseudo-elemento para pantallas grandes */
.home h1::before {
  content: "";
  display: none;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 767px) {
  .home h1 {
    font-size: 0; /* Oculta el texto original */
  }

  .home h1::before {
    content: "FRUTICOLA HACIENDA MACUL"; /* Texto para pantallas pequeñas */
    display: block;
    font-size: 20px; /* Ajusta según sea necesario */
    white-space: pre; /* Si necesitas mantener los saltos de línea */
  }

}

@media (max-width: 767px) {
  .description {
    font-size: 20px; /* Tamaño de fuente específico para móviles */
    line-height: 1; /* Interlineado más estrecho específico para móviles */
    padding: 0 10px; /* Se mantiene */
    margin-top: 10px; /* Se mantiene */
  }
}



/*
@media (max-width: 767px) {
  .home h1 {
    font-size: 30px;
    white-space: normal;
    line-height: 1.2;
    text-align: center;
  }

  .home h1::before {
    content: "FRUTICOLA\a HACIENDA\a MACUL";
    white-space: pre;
    display: block;
    font-size: 30px;
  }

  .description {
    font-size: 18px;
    line-height: 1.2;
    padding: 0 10px;
    margin-top: 10px;
  }

  .content,
  .carousel-container {
    width: 100%;
    padding: 0;
  }
}

*/